<template>
  <div class="page-content">
    <div class="container-fluid">
      <Breadcrumb
        :pgTitle="$t('nav.employees')"
        :pgIcon="'bx bx-user'"
        :refs="refs"
        :pgCurrent="pgCurrent"
        :addNew="false"
      >
      </Breadcrumb>

      <div class="row">
        <Loading v-if="pgLoading"> </Loading>

        <div v-if="!pgLoading" class="col-8 col-sm-12">
          <FormSingleLang
            :label="$t('view.user_info')"
            :labelName="$t('view.name')"
            :labelEmail="$t('view.email')"
            :labelPassword="$t('view.password')"
            :labelCountryCode="$t('view.country_code')"
            :labelMobile="$t('view.mobile')"
            :labelCivilID="$t('view.civil_id')"
            :labelUsername="$t('view.username')"
            :hasUsername="true"
            :username="row.username"
            :disableUserName="disableUsername"
            :hasName="true"
            :name="row.name"
            :hasCivilID="true"
            :civil_id="row.civil_id"
            :hasEmail="true"
            :email="row.email"
            :hasPassword="true"
            :password="row.password"
            :hasCountryCode="true"
            :mobile_country_code="row.country_code"
            :hasMobile="true"
            :mobile="row.mobile"
            v-on:formChange="handleFormChange"
          >
          </FormSingleLang>
        </div>

        <div v-if="!pgLoading" class="col-4 col-sm-12">
          <!--          <CardImage-->
          <!--            :labelImage="$t('view.image')"-->
          <!--            :image_base64="row.image_base64"-->
          <!--            :image_preview="row.image_preview"-->
          <!--            v-on:imageChange="handleImageChange"-->
          <!--          >-->
          <!--          </CardImage>-->

          <!--          <CardRole :role="row.role" v-on:roleChange="handleRoleChange">-->
          <!--          </CardRole>-->
          <CardCompany
            v-if="auth.role == 'root'"
            :company_id="row.company_id"
            @companyChange="handleCompanyChange"
          />
          <CardStatus
            :status="row.status"
            v-on:statusChange="handleStatusChange"
          >
          </CardStatus>
        </div>
      </div>

      <Buttons
        v-if="!pgLoading"
        :btnLoading="btnLoading"
        :btnCurrent="btnCurrent"
        v-on:submitClicked="handelSubmit"
        v-on:cancelClicked="handleCancel"
      >
      </Buttons>
    </div>
  </div>
</template>

<script>
import iziToast from "izitoast";
import Loading from "@/components/Loading";
import FormSingleLang from "@/components/FormSingleLang";
// import CardImage from "@/components/CardImage";
import CardStatus from "@/components/CardStatus";
import Buttons from "@/components/Buttons";
import Breadcrumb from "@/components/Breadcrumb";
// import CardRole from "@/components/CardRole";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import CardCompany from "@/components/CardCompany.vue";

export default {
  name: "EditOrNew",
  components: {
    CardCompany,
    Breadcrumb,
    // CardRole,
    Loading,
    FormSingleLang,
    // CardImage,
    CardStatus,
    Buttons,
  },
  data() {
    return {
      // auth
      auth: {
        role: "",
        id_token: "",
      },

      // row
      row: {
        name: "",
        email: "",
        password: "",
        country_code: "",
        mobile: "",
        civil_id: "",
        username: "",
        role: "employee",
        // image_base64: "",
        // image_preview: "",
        company_id: null,
        status: 1,
      },
      msgCurrent: this.$route.params.id ? "Updated" : "Created",
      btnCurrent: this.$route.params.id ? "Update" : "Create",
      methodType: this.$route.params.id ? "PUT" : "POST",
      pgLoading: this.$route.params.id ? true : false,
      pgCurrent: this.$route.params.id ? "Edit" : "Add New",
      btnLoading: false,
      disableUsername: false,
      refs: "employees",
    };
  },
  created() {
    // AccessToken & Role
    if (localStorage.getItem("id_token")) {
      this.auth.id_token = localStorage.getItem("id_token");
    }
    if (localStorage.getItem("role_key")) {
      this.auth.role = localStorage.getItem("role_key");
    }

    if (this.$route.params.id) {
      this.fetchRow();
    }
    setCurrentPageTitle(this.$t("nav.employees"), "bx bx-user");
  },
  methods: {
    // fetch Row
    fetchRow() {
      this.pgLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.id_token,
      };
      const options = {
        url: this.refs + "/" + this.$route.params.id,
        method: "GET",
        data: {},
      };
      this.axios(options)
        .then((res) => {
          this.pgLoading = false;

          this.row.name = res.data.row.name;
          this.row.email = res.data.row.email;
          this.row.civil_id = res.data.row.civil_id;

          this.row.country_code = res.data.row.country_code;
          this.row.mobile = res.data.row.mobile;
          this.row.username = res.data.row.username;
          if (this.row.username) this.disableUsername = true;
          this.row.company_id = res.data.row.company_id;

          // this.row.image_preview = res.data.row.image
          //   ? res.data.row.image.url
          //   : "";
          // this.row.image_base64 = res.data.row.image
          //   ? res.data.row.image.url
          //   : "";

          this.row.role = res.data.row.role;
          this.row.status = res.data.row.status;
        })
        .catch((err) => {
          // 403 Forbidden
          if (err.response && err.response.status == 401) {
            this.clearLocalStorage();
            this.$router.push({ name: "login" });
          } else if (err.response && err.response.status == 403) {
            this.$router.push({ name: "forbidden" });
          } else {
            this.btnLoading = false;
            iziToast.warning({
              icon: "bx bx-angry",
              title: "",
              message: err.response ? err.response.data.message : "" + err,
            });
          }
        });
    },

    // handle edit Or New submit
    handelSubmit() {
      this.btnLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.id_token,
      };
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      const options = {
        url: this.$route.params.id
          ? this.refs + "/" + this.$route.params.id
          : this.refs,
        method: this.methodType,
        data: {
          name: this.row.name,
          email: this.row.email,
          password: this.row.password,
          civil_id: this.row.civil_id,
          country_code: this.row.country_code,
          mobile: this.row.mobile,
          // image_base64: this.row.image_base64,
          username: this.row.username,
          status: this.row.status,
          role: this.row.role,
          company_id: this.row.company_id,
        },
      };
      this.axios(options, config)
        .then((res) => {
          this.btnLoading = false;
          if (
            res.error &&
            res.error.response &&
            res.error.response.status == 422
          ) {
            this.btnLoading = false;
            iziToast.warning({
              icon: "bx bx-angry",
              title: "",
              message: res.error.response
                ? res.error.response.data.message
                : "" + res.error,
            });
          } else {
            iziToast.success({
              icon: "bx bx-wink-smile",
              title: "",
              message:
                this.btnCurrent == "Update"
                  ? "تم التعديل بنجاح"
                  : "تم الإضافة بنجاح",
            });
            this.$router.push({ name: this.refs });
          }
        })
        .catch((err) => {
          // 403 Forbidden
          if (err.response && err.response.status == 401) {
            this.clearLocalStorage();
            this.$router.push({ name: "login" });
          } else if (err.response && err.response.status == 403) {
            this.$router.push({ name: "forbidden" });
          } else {
            this.btnLoading = false;
            iziToast.warning({
              icon: "bx bx-angry",
              title: "",
              message: err.response ? err.response.data.message : "" + err,
            });
          }
        });
    },

    // clear Local Storage
    clearLocalStorage() {
      localStorage.removeItem("id_token");
      localStorage.removeItem("avatar");
      localStorage.removeItem("username");
      localStorage.removeItem("encrypt_id");
      // localStorage.removeItem("role");
    },

    // Cancel
    handleCancel() {
      if (confirm("هل انت متأكد؟")) {
        this.$router.push({ name: this.refs });
      }
    },
    handleCompanyChange(event) {
      this.row.company_id = event.id;
    },
    // handel Changes from child components
    handleFormChange(event) {
      this.row.name = event.name;
      this.row.email = event.email;
      this.row.civil_id = event.civil_id;
      this.row.password = event.password;
      this.row.country_code = event.mobile_country_code;
      this.row.mobile = event.mobile;
      this.row.username = event.username;
    },

    // handleImageChange(event) {
    //   this.row.image_base64 = event.image_base64;
    // },

    // handleRoleChange(event) {
    //   this.row.role = event.role;
    // },

    handleStatusChange(event) {
      this.row.status = event.status;
    },
  },
};
</script>
