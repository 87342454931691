<template>
  <div class="card">
    <div class="card-body">
      <h4
        class="card-title"
        :class="$i18n.locale == 'ar' ? 'font-size-15' : ''"
      >
        {{ $t("app.choose_company") }}
      </h4>
      <div class="col-md-12 text-center" style="padding: 0">
        <span
          v-if="companyLoading"
          class="spinner-grow spinner-grow-sm mr-1"
          role="status"
          aria-hidden="true"
        >
        </span>
        <select
          v-if="!companyLoading"
          id="companies"
          class="form-control"
          :class="$i18n.locale == 'ar' ? 'font-size-15' : ''"
          v-model="row.company_id"
          v-on:change="onInputChange"
        >
          <option v-for="(row, idx) in companies" :key="idx" :value="row.id">
            {{ row[$i18n.locale]["name"] }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import iziToast from "izitoast";

export default {
  name: "CardCompany",
  props: ["company_id", "category_id", "bus"],
  data() {
    return {
      auth: {
        id_token: "",
      },
      row: {
        company_id: this.$props.company_id,
      },
      companies: [],
      companyLoading: true,
      current_category_id: this.$props.category_id,
    };
  },
  created() {
    if (localStorage.getItem("id_token")) {
      this.auth.id_token = localStorage.getItem("id_token");
    }

    if (this.bus != undefined) {
      this.bus.on("category_changed", (category_id) => {
        this.current_category_id = category_id;
        this.fetchData();
      });
    }

    this.fetchData();
  },
  methods: {
    onInputChange() {
      const company = this.companies.find(
        (company) => company.id == this.row.company_id
      );
      this.$emit("companyChange", company);
    },

    fetchData() {
      this.companyLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.id_token,
      };
      const options = {
        url: "/companies",
        method: "GET",
        data: {},
        params: {
          category_id: this.current_category_id,
          status: "active",
          paginate: 1000,
        },
      };
      this.axios(options)
        .then((res) => {
          this.companyLoading = false;
          this.companies = res.data.data.rows;
        })
        .catch((err) => {
          this.companyLoading = false;
          iziToast.warning({
            icon: "bx bx-angry",
            title: "",
            message: err.response ? err.response.data.message : "" + err,
          });
        });
    },
  },
};
</script>
